@import url("bootstrap/bootstrap.scss");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons");

$color-primary: #0267d4;
$color-secondary: #d8d8d8;
$color-success: #5cb85c;
$color-danger: #d9534f;
$color-warning: #f0ad4e;
$color-info: #5bc0de;
$color-light: #f3f4f7;
$color-dark: #333333;
$color-body: #333333;
$color-muted: #9d9d9d;
$color-white: #ffffff;
$color-black-50: rgba(0, 0, 0, 0.5);
$color-white-50: rgba(255, 255, 255, 0.5);

$header-height: 70px;
$side-nav-width: 280px;

$completed: #d8d8d8;
$scheduled: #9d9d9d;
$waitlist: #fd7e14;
$confirm: #0dcaf0;
$cancel: #f5e832;
$p-pass: #5cb85c;
$p-fail: #d9534f;
$blockt: #0267d4;
$chkin: #774087;
$flot: #f8be67;

$li-completed: #f9fafc;
$li-scheduled: #e6e6e6;
$li-waitlist: #ffdbbf;
$li-confirm: #c2f2fb;
$li-cancel: #fdf9cb;
$li-p-pass: #d6edd6;
$li-p-fail: #f6d4d3;
$li-blockt: #bfd9f4;
$li-chkin: #e4d9e7;
$li-flot: #fff9ef;

@import "common";
@import "header";
@import "sidebar";
@import "myScheduleTable";
@import "avatar";
@import "subscription";
@import "chat";
@import "login";

.newAvtr {
    position: absolute;
    left: -40px;
    top: 0;
    width: 36px;
    height: 36px;
    font-size: 14px;
    .MuiAvatar-root {
        font-size: 14px;
        height: 100%;
        width: 100%;
    }
}
.adminListTable {
    table {
        margin-bottom: 0;
    }
    th {
        border-bottom: 1px solid #dee2e6 !important;
        padding: 7px 15px;
    }
    td {
        vertical-align: middle;
        padding: 7px 15px;
    }
    tr:last-child {
        td {
            border-bottom: none;
        }
    }
    .as_bottom {
        border-top: 1px solid #dee2e6;
        padding: 30px 15px;
    }
}
.chat_params {
    .avatar {
        min-width: 40px;
        min-height: 40px;
    }
}
.provider-list-sec {
    .advances-table {
        td.cursor-pointer {
            .avatar {
                min-width: 48px;
                min-height: 48px;
            }
            .proId {
                font-size: 14px;
            }
        }
        td.text-end {
            text-align: center;
            .form-check.form-switch.float-end {
                float: none !important;
                position: relative;
                padding-left: 0 !important;
            }
            .form-check-label {
                text-align: center;
                display: block;
                margin-right: 0 !important;
                padding-top: 25px;
                line-height: 16px;
            }
            .form-check-input {
                float: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                margin: 0 auto;
            }
        }
    }
}
.patientsearch-sec {
    td {
        .email-txt {
            word-wrap: anywhere;
        }
        .sms-switch {
            position: relative;
            padding: 0;
            label {
                display: block;
                text-align: center;
                width: 90px;
                margin: 35px auto 0 auto;
                line-height: 18px;
            }
            .form-check-input {
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                margin: -30px auto 0 auto;
            }
        }
    }
}
.group-chat-header,
.group-pin-icon {
    background-color: #e62b4a !important;
    .pintype {
        background-color: #e62b4a !important;
    }
}
.group-chat-label {
    color: #e62b4a;
}
.chat-msg-inline {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
    width: 180px;
    white-space: nowrap;
    opacity: 0.7;
}
.chatbox-sc {
    .alert {
        font-size: 14px;
        .alert-date-txt {
            opacity: 0.5;
        }
    }
}
.not-supported-box {
    height: 100vh;
    p {
        font-weight: 500;
    }
}
.footer-content {
    font-size: 14px;
    text-align: right;
}
.chat-user-details {
    margin-top: 7px;
    div.fw-medium {
        line-height: 14px;
    }
}
.schedule-sec .btn-link {
    font-size: 13px !important;
}
.clinic-schedule-sec {
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.today-button {
        padding: 3px 15px;
    }
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiOutlinedInput-input {
        padding: 3px 25px 3px 15px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        margin-top: 5px;
        .MuiSelect-icon {
            top: -1px;
        }
    }
    .MuiTableCell-root.MuiTableCell-body.text-center [class*="jss"] {
        font-size: 14px;
    }
    table.cfxwidth tr td {
        min-width: 180px;
    }
}

@media only screen and (max-width: 1449px) {
    // body {
    //     font-size: 14px !important;
    // }
    // .avatar-lg {
    //     width: 40px;
    //     height: 40px;
    //     line-height: 40px;
    //     & > * {
    //         line-height: 40px;
    //     }
    // }
    // .btn {
    //     font-size: 14px;
    // }
    // .footer-content {
    //     font-size: 12px;
    //     text-align: right;
    // }
    // .dropdown-item,
    // .dropdown-menu {
    //     font-size: 14px;
    // }
    // input,
    // select {
    //     font-size: 14px !important;
    // }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    // body {
    //     font-size: 13px !important;
    // }
    // input,
    // select {
    //     font-size: 13px !important;
    // }
    // .dropdown-item,
    // .dropdown-menu {
    //     font-size: 13px;
    // }
    // .btn {
    //     font-size: 13px;
    // }

    .MuiTableCell-root.MuiTableCell-body.text-center [class*="jss"] {
        font-size: 13px !important;
        display: flex !important;
        align-items: center;
        height: 100%;
        text-align: center;
        padding: 0 7px !important;
        justify-content: center;
    }
    .patientsearch-sec,
    .recall-recare-sec {
        .advances-table table td {
            max-width: none;
        }
    }
    .patient-detaols-popup {
        .modal-header .modal-title {
            font-size: 24px;
            line-height: 30px;
        }
        .chat-pdetail-cnt {
            height: auto;
            margin-top: 0;
        }
    }

    .provider-list-sec {
        .advances-table {
            td {
                max-width: 150px;
            }
            td.cursor-pointer {
                min-width: 210px;
                .avatar {
                    width: 40px;
                    height: 40px;
                }
            }
            td.text-end {
                text-align: center;
                .form-check.form-switch.float-end {
                    float: none !important;
                    position: relative;
                    padding-left: 0 !important;
                }
                .form-check-label {
                    text-align: center;
                    display: block;
                    margin-right: 0 !important;
                    padding-top: 25px;
                    line-height: 16px;
                }
                .form-check-input {
                    float: none;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin: 0 auto;
                }
            }
        }
    }
    .chat-message-right .MuiAvatar-root.MuiAvatar-circle {
        font-size: 11px;
        width: 24px;
        height: 24px;
        left: -30px;
    }
    .chat_params {
        .avatar {
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            line-height: 32px;
        }
        .view-info-btn {
            height: 32px;
            width: 32px;
            padding: 0;
            font-size: 20px;
            border-radius: 50%;
        }
    }
    .chatbox-sc .alert {
        font-size: 13px;
    }
    .subscription-sec {
        .default-card {
            .addon-list {
                .p-4 {
                    padding-left: 1rem !important;
                    padding-right: 1rem !important;
                }
                .info-box {
                    font-size: 18px !important;
                }
                .next-date {
                    .fw-medium.fs-5 {
                        font-size: 18px !important;
                    }
                }
            }
        }
        .price-tag {
            .fs-1 {
                font-size: 32px !important;
            }
            .position-relative {
                top: -7px !important;
            }
        }
        .info-box-addons {
            div.text-muted.small {
                max-width: 300px;
                width: 100%;
            }
            div.btn {
                height: 36px;
            }
        }
    }
    .waitlist-box .table {
        td:nth-child(5),
        th:nth-child(5) {
            display: none;
        }
        th:last-child {
            width: 80px !important;
        }
    }
    .upcoming-table {
        th:last-child {
            width: 80px !important;
        }
    }
    .patientsearch-sec {
        .advances-table {
            td:nth-child(2) .avatar {
                display: none;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .main-sidebar {
        display: none;
    }
    .main-middle-sec {
        padding: 0;
    }

    .schedule-sec,
    .advances-table {
        tr {
            td {
                display: block;
                float: left;
            }
        }
    }
}

.fixed-chart {
    margin-bottom: 30px;
    .chart-card {
        padding: 20px;
        height: 152px;
        .txt-box {
            .value_num {
                line-height: normal;
            }
            .type_title {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .chartdata {
            margin-bottom: 30px;
        }
        .avatar {
            width: 50px;
            height: 50px;
            font-size: 20px;
            line-height: 50px;
        }
    }
}

.color_line_chart {
    width: 100%;
    height: 6px;
    border-radius: 6px;
    background: rgb(10, 210, 4);
    background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(236, 236, 0, 1) 50%, rgba(10, 210, 4, 1) 100%);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    position: relative;
    .target {
        display: block;
        height: 14px;
        width: 3px;
        background-color: #333333;
        border: 1px solid #fff;
        position: absolute;
        top: -4px;
        span {
            position: absolute;
            left: -30px;
            display: block;
            line-height: 10px;
            font-size: 9px;
            color: #999;
            top: 16px;
            width: 60px;
            text-align: center;
        }
    }
    .current_count {
        position: absolute;
        top: -24px;
        margin-left: -16px;
    }
    &.patient_santi {
        .unhappy {
            position: absolute;
            left: 0;
            top: 10px;
            color: rgba(255, 0, 0, 1);
        }
        .happy {
            position: absolute;
            right: 0;
            top: 10px;
            color: rgba(10, 210, 4, 1);
        }
        .senti_status {
            position: absolute;
            top: -30px;
            right: 0;
            font-size: 18px;
            color: rgba(10, 210, 4, 1);
            font-weight: 600;
        }
    }
}

.dynamic-chart {
    border-top: 1px solid #d8d8d8;
    padding-top: 30px;

    .card {
        height: 100%;
    }

    .bottom-data {
        .btms-data {
            text-align: center;
            flex: 1 1 0;
            span {
                font-size: 12px;
                color: #9d9d9d;
                line-height: 16px;
                display: block;
            }
            div {
                font-size: 24px;
                color: #333333;
                line-height: 30px;
                font-weight: 600;
            }
        }
    }

    .graph-area {
        width: 100%;
        height: 150px;
        border-radius: 10px;
        background: #f3f4f7;
        border: 1px solid #d8d8d8;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        margin-bottom: 20px;
    }
}

.glist-item {
    display: grid;
    grid-template-columns: 40% auto 100px 30px;
    align-items: center;
    column-gap: 20px;
}

table {
    &.th-bg {
        th {
            background-color: #f6f6f6;
        }
    }
}
.setting-config-sec {
    .card {
        overflow: hidden;
        padding: 10px 30px;
        .list-group {
            li {
                padding: 15px 0;
            }
        }
    }
}

.ellipsis-max-width {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sentiment-section .card-body .graph-layout canvas {
    margin: 0px auto;
}

/*------------------ New CSS ---------------------*/ 
.main-middle-sec.shrink .matrix .col-3 {
    flex: 0 0 auto;
    width: 25%;
}
.main-middle-sec .matrix .col-3 {
    flex: 0 0 auto;
    width: 33.33%;
}
.graphs .col-md-6 .card.sentiment-section .card-body .graph-layout {
    overflow-y: hidden;
}
.graphs .col-md-6 .card.sentiment-section .card-body .graph-layout::-webkit-scrollbar {
  height: 3px;
}

.graphs .col-md-6 .card.sentiment-section .card-body .graph-layout::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.graphs .col-md-6 .card.sentiment-section .card-body .graph-layout::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #CCC;
}

.graphs .col-md-6 .card.sentiment-section .card-body .graph-layout::-webkit-scrollbar-thumb:hover {
  background-color: #CCC;
}
.main-sidebar{
    z-index: 22;
}

.ant-table {
    display: block;
    overflow: auto;
}