body {
  color: $color-dark;
  font-family: "Roboto", sans-serif;
}
.fw-medium {
  font-weight: 500;
}
.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

ul {
  list-style-type: none;
}
// Configuration of template

label {
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.form-check-label {
  font-weight: 400;
}
.form-control::placeholder {
  opacity: 0.5;
}

.newcommon {
  .form-check-label {
    color: $color-muted;
  }
  .form-check-input:checked ~ label {
    color: $color-dark;
  }
}
.modal-backdrop.show {
  opacity: 0.7;
}

.main-middle-sec {
  padding-left: $side-nav-width;
  padding-top: $header-height;
}

.middle-navbar {
  padding-top: 20px;
  border-bottom: 1px solid $color-secondary;
  .nav-tabs {
    border: 0;
    gap: 40px;
    .nav-link {
      color: $color-muted;
      border: 0;
      margin: 0;
      padding: 0 0 10px 0;
      position: relative;
      font-size: 16px;
    }

    .nav-link.active {
      color: $color-dark;
      font-weight: 500;
      border: none;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: $color-dark;
        left: 0;
        bottom: 0;
      }
    }
  }

  .nav-title-txt {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 15px;
  }
}
.bg-waitlist {
  background-color: $waitlist;
}
.avatar-group {
  .avatar {
    position: relative;
    z-index: 2;
    border: 2px solid #fff;
    &:hover {
      z-index: 3;
    }
  }
  .avatar + .avatar {
    margin-left: -0.8rem;
  }
}

.btn-link {
  text-decoration: none !important;
  &::hover {
    background: none !important;
  }
}

// common popup css

.newcommon {
  &.addnewappt {
    .modal-dialog {
      max-width: 550px;
    }
  }

  .modal-header {
    margin: 0 30px 0 30px;
    padding: 20px 0 20px 0;
    .modal-title {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .modal-content {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
  .modal-body {
    padding: 20px 30px;
  }
  .modal-footer {
    border: none;
    padding: 0 30px 30px 30px;
  }
}

.miuif_margin {
  .MuiInput-formControl {
    margin: 0;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .MuiInput-underline::after,
  .MuiInput-underline::before {
    display: none;
  }
  .MuiInputBase-input {
    padding: 0;
    height: auto;
  }
  .MuiIconButton-root {
    padding: 0;
  }
}

.fx-accordion-height {
  max-height: 290px;
  overflow-x: hidden;
  overflow-y: visible;
  -ms-overflow-style: none;
  /*IEandEdge*/
  scrollbar-width: none;
  /*Firefox*/
  margin-bottom: 20px;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
}

.fs-7 {
  font-size: 14px;
}
.backtohome {
  right: 20px;
  top: 20px;
}

// temp css

.main-middle-sec {
  &.page-container {
    .main-content {
      padding: 0;
      min-height: calc(100vh - 151px);
      position: relative;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.is-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.stripe-card {
  .StripeElement {
    padding: 10px;
  }
}

// calenders appoints colours

.chip_cc {
  display: grid;
  align-content: center;
  padding: 3px 10px;
}

// Completed:
.ms-completed {
  background-color: $li-completed !important;
  border-radius: 5px !important;
  border-color: $completed !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// Schedule:
.ms-schedule {
  background-color: $li-scheduled !important;
  border-radius: 5px !important;
  border-color: $scheduled !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.ms-waitlist {
  background-color: $li-waitlist !important;
  border-radius: 5px !important;
  border-color: $waitlist !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// Confirm:
.ms-confirm {
  background-color: $li-confirm !important;
  border-radius: 5px !important;
  border-color: $confirm !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// blocktime
.ms-block-time {
  background-color: $li-blockt !important;
  border-radius: 5px !important;
  border-color: $blockt !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// checkin
.ms-checkin {
  background-color: $li-chkin !important;
  border-radius: 5px !important;
  border-color: $chkin !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// Cancel + pms yellow:
.ms-cancel,
.pms-yellow {
  background-color: $li-cancel !important;
  border-radius: 5px !important;
  border-color: $cancel !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// Prescreening Pass: + PMS green
.ms-prescreen-pass,
.pms-green {
  background-color: $li-p-pass !important;
  border-radius: 5px !important;
  border-color: $p-pass !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// Prescreening Fail: + PMS Red
.ms-prescreen-fail,
.pms-red {
  background-color: $li-p-fail !important;
  border-radius: 5px !important;
  border-color: $p-fail !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// floating
.ms-floating {
  background-color: $li-flot !important;
  border-radius: 5px !important;
  border-color: $flot !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

// Completed:
.status-completed {
  background-color: $completed !important;
}

// Schedule:
.status-schedule {
  background-color: $scheduled !important;
}

.status-waitlist {
  background-color: $waitlist !important;
}

// Confirm:
.status-confirm {
  background-color: $confirm !important;
}

//  checkin:
.status-checkin {
  background-color: $chkin !important;
}

// status-block-time:
.status-block-time {
  background-color: $blockt !important;
}

// Cancel + pms yellow:
.status-cancel {
  background-color: $cancel !important;
}

// Prescreening Pass: + PMS green
.status-prescreen-pass {
  background-color: $p-pass !important;
}

// Prescreening Fail: + PMS Red
.status-prescreen-fail {
  background-color: $p-fail !important;
}

// floating
.status-floating {
  background-color: $flot !important;
}

// for bottom chat

.status-schedule-chat .fab.avatar,
.status-schedule-chat .pintype,
.status-schedule-chat .chat_header {
  background: $scheduled !important;
}
.status-waitlist-chat .fab.avatar,
.status-waitlist-chat .pintype,
.status-waitlist-chat .chat_header {
  background: $waitlist !important;
}
.status-confirm-chat .fab.avatar,
.status-confirm-chat .pintype,
.status-confirm-chat .chat_header {
  background-color: $confirm !important;
}
.status-cancel-chat .fab.avatar,
.status-cancel-chat .pintype,
.status-cancel-chat .chat_header {
  background-color: $cancel !important;
}
.status-prescreen-fail-chat .fab.avatar,
.status-prescreen-fail-chat .pintype,
.status-prescreen-fail-chat .chat_header {
  background-color: $p-fail !important;
}
.status-prescreen-pass-chat .fab.avatar,
.status-prescreen-pass-chat .pintype,
.status-prescreen-pass-chat .chat_header {
  background-color: $p-pass !important;
}
.chat_header .group-chat-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  i {
    font-size: 16px;
    position: relative;
    top: 1px;
  }
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}
.colour-guideline {
  .cguide {
    height: 16px;
    width: 16px;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 0 -1.5px;
  }
  .cguide {
    &.completed {
      background: $li-completed;
      border: 1px solid $completed;
    }
    &.scheduled {
      background: $li-scheduled;
      border: 1px solid $scheduled;
    }
    &.waitlist {
      background: $li-waitlist;
      border: 1px solid $waitlist;
    }
    &.confirmed-bp {
      background: $li-confirm;
      border: 1px solid $confirm;
    }
    &.cancelled-bp {
      background: $li-cancel;
      border: 1px solid $cancel;
    }
    &.prescreening-p {
      background: $li-p-pass;
      border: 1px solid $p-pass;
    }
    &.prescreening-f {
      background: $li-p-fail;
      border: 1px solid $p-fail;
    }
    &.blockt {
      background: $li-blockt;
      border: 1px solid $blockt;
    }
    &.chkin {
      background: $li-chkin;
      border: 1px solid $chkin;
    }
    &.flot {
      background: $li-flot;
      border: 1px solid $flot;
    }
  }
}

// div [class*="Header-flexContainer-"] {
//     display: none !important;
// }

.global_loder {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  .loder_in {
    padding: 30px;
    display: inline-block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
}

.content-spinner {
  padding: 30px;
  display: inline-block;
  position: fixed;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  z-index: 999999;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.dropdown.dropdown-animated:not(.scale-left) .dropdown-menu,
.dropdown.dropdown-animated:not(.scale-right) .dropdown-menu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scaleY(0.65) !important;
  transform: scaleY(0.65) !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  display: block;
  margin: 0px;
  top: auto !important;
}
.dropdown.dropdown-animated:not(.scale-left) .dropdown-menu.show,
.dropdown.dropdown-animated:not(.scale-right) .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1) !important;
  transform: scaleY(1) !important;
}
.dropdown.dropdown-animated.scale-left .dropdown-menu {
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transform: scale(0, 0) !important;
  -webkit-transform: scale(0, 0) !important;
  -moz-transform: scale(0, 0) !important;
  -ms-transform: scale(0, 0) !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  display: block;
  margin: 0px;
  right: 0 !important;
  left: auto !important;
  top: auto !important;
}
.dropdown.dropdown-animated.scale-left .dropdown-menu.show {
  transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -ms-transform: scale(1, 1) !important;
}
.dropdown.dropdown-animated.scale-right .dropdown-menu {
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transform: scale(0, 0) !important;
  -webkit-transform: scale(0, 0) !important;
  -moz-transform: scale(0, 0) !important;
  -ms-transform: scale(0, 0) !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  display: block;
  margin: 0px;
  top: auto !important;
}
.dropdown.dropdown-animated.scale-right .dropdown-menu.show {
  transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -ms-transform: scale(1, 1) !important;
}

.editor-box * {
  font-family: "Roboto", sans-serif;
}
.avatar {
  text-transform: uppercase;
}

.overlay-center {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  height: calc(100vh - 70px);
  width: 100%;
  z-index: 98;
  overflow: hidden;
  top: 0;
}
.vertical-center {
  margin: 0;
  left: 50%;
  position: fixed;
  top: calc(50% + 35px);
  width: 300px;
  transform: translateY(-50%);
}
.centermsg {
  height: calc(100vh - 150px);
}

.react-confirm-alert-body {
  text-align: center !important;
  font-family: inherit !important;
}
.react-confirm-alert-button-group {
  justify-content: center !important;
}
.planpopup {
  .plantbl {
    text-align: center;
    &.table {
      border: 1px solid #333;
    }
    td,
    th {
      vertical-align: middle;
      padding: 2px 10px;
      &.bg-success {
        background: #5cb85c !important;
      }
    }
  }
}

//fullscreen
body {
  &.full-screen {
    .main-header,
    .main-sidebar {
      display: none !important;
    }
    .main-middle-sec {
      padding: 0;
    }
  }
}

.mergeappt-popup {
  .modal-dialog {
    max-width: 950px;
  }
}
.stp-action {
}

// toaster

.Toastify__toast {
  font-size: 14px;
  line-height: 18px;
  min-height: 60px;
  padding: 12px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  .Toastify__progress-bar {
    height: 2px;
    opacity: 1;
  }
}
.Toastify__toast--default {
  background: #fff;
  color: #333;
  border: 1px solid #333;
  .Toastify__progress-bar {
    background-color: #999;
  }
}
.Toastify__toast--info {
  background: #dcedf6;
  color: #3d708d;
  border: 1px solid #3d708d;
  .Toastify__progress-bar {
    background-color: #3d708d;
  }
}
.Toastify__toast--success {
  background: #e2eed8;
  color: #4b713d;
  border: 1px solid #4b713d;
  .Toastify__progress-bar {
    background-color: #4b713d;
  }
}
.Toastify__toast--warning {
  background: #fcf7e4;
  color: #876d40;
  border: 1px solid #876d40;
  .Toastify__progress-bar {
    background-color: #876d40;
  }
}
.Toastify__toast--error {
  background: #efdfdf;
  color: #9f4f48;
  border: 1px solid #9f4f48;
  .Toastify__progress-bar {
    background-color: #9f4f48;
  }
}

.chat_header {
  .charlimit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
.fs-12 {
  font-size: 12px !important;
}

.yesav {
  max-width: calc(100% - 60px);
}
.charlmt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.quick-access-cal {
  position: absolute;
  left: -50px;
  top: 0;
  ul {
    padding: 0;
    margin: 0;
    li {
      margin-top: 10px;
    }
  }
  .btn {
    height: 40px;
    width: 40px;
    span {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.MuiPaper-root {
  overflow: visible !important;
}

.cal-pop-modify {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  margin: -12px 0 0 0;
}

.MuiPopover-root {
  z-index: 99 !important;
}

.familycard {
  .active {
    .card {
      background-color: $color-primary !important;
      * {
        color: #fff;
      }
      .text-muted {
        color: #fff !important;
      }
      .avatar {
        background-color: #fff;
        color: #333;
      }
    }
  }
}

.provi-holiday {
  .MuiTextField-root {
    width: 100%;
  }
}

table {
  &.cfxwidth {
    table-layout: inherit;
    tr {
      td {
        min-width: 200px;
        div[class^="makeStyles-text-"] {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 180px;
        }
      }
    }
  }
}
.calshow_style {
  td {
    min-width: 200px;
  }
}

@media screen and (min-width: 1441px) {
  table {
    &.cfxwidth {
      table-layout: inherit;
      tr {
        td {
          min-width: 250px;
          div[class^="makeStyles-text-"] {
            max-width: 180px;
          }
        }
      }
    }
  }
  .calshow_style {
    td {
      min-width: 250px;
    }
  }
}

@media screen and (min-width: 1921px) {
  table {
    &.cfxwidth {
      table-layout: fixed;
    }
  }
}

.cal-arrow {
  position: absolute;
  right: 100px;
  top: 100px;
  z-index: 1;
}

.modal {
  &.bgclick {
    width: inherit;
  }
  .infinite-scroll-component {
    overflow: hidden !important;
  }
}
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  border-radius: 0;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.modal-backdrop {
  &.remove-trans {
    display: none;
  }
}

.modal-open {
  .MuiPopover-root {
    display: none;
  }
}

// by hasan for sidebar shrink

.main-sidebar * {
  transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
}
.main-middle-sec {
  transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
}

.main-sidebar.shrink::-webkit-scrollbar {
  display: none;
}

.main-sidebar.shrink {
  width: 80px;
  overflow-x: inherit;
  z-index: 99;
  padding: 0;

  .sidebar-menu {
    ul.main-menu-list {
      margin: 0;
      li.sidebar-items {
        &.hasSubmenu {
          &:hover {
            .sub-menu-list {
              display: block !important;
            }
          }
          .sub-menu-list {
            position: fixed;
            top: 70px;
            left: 79px;
            height: 100vh;
            background: #fff;
            border-right: 1px solid #d8d8d8;
            border-left: 1px solid #d8d8d8;
            padding: 20px;
            margin: 0 !important;
            min-width: 160px;
            &.collapse {
              display: none;
            }
            &.show {
              display: none;
            }
          }
        }
        a.active {
          &:before {
            left: 0;
            top: 0;
          }
        }
        .icon-box {
          .sp-badge {
            display: inherit;
          }
        }
      }
    }
  }

  .profile-sec {
    background: none;
    border: none !important;
    .txt-box {
      display: none;
    }
    .avatar {
      margin: auto !important;
    }
  }
  .sidebar-menu {
    .sidebar-header {
      display: none;
    }
    .txt-box {
      display: none;
    }
    .badge {
      display: none;
    }
    .icon-box {
      margin: 5px 25px !important;
    }
  }

  ul.main-menu-list li.sidebar-items.hasSubmenu .navaccordion::after {
    display: none;
  }
}

.main-middle-sec.shrink {
  padding-left: 80px;
}

.call-start {
  margin-top: 60px;
}
.shrink {
  .call-strip {
    width: calc(100% - 80px) !important;
    left: 80px;
  }
}
.call-strip {
  background: #000;
  padding: 10px 20px;
  top: 70px;
  left: 280px;
  position: fixed;
  width: calc(100% - 280px);
  height: 60px;
}

.temp-chart {
  &.adjstimg img {
    max-width: 100%;
    transform: scale(1.25);
    position: relative;
    top: -12px;
  }
  img {
    max-width: 100%;
  }
  .card {
    overflow: hidden;
    margin: 10px;
    flex: 0 0 23%;
    min-height: 40vh;
    .container-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .tool-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.6rem;
        i {
          cursor: pointer;
        }
      }
      .chart-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

//task manager css
.add_new_task {
  border-radius: 3px;
  border-bottom: 1px solid rgb(204, 204, 204);
  background-color: rgb(255, 255, 255);
  position: relative;
  padding: 10px;
  cursor: pointer;
  max-width: 250px;
  margin-bottom: 7px;
  min-width: 230px;
}

.task_card {
  border-radius: 3px;
  border-bottom: 1px solid rgb(204, 204, 204);
  background-color: rgb(255, 255, 255);
  position: relative;
  padding: 10px;
  cursor: pointer;
  max-width: 250px;
  margin-bottom: 7px;
  min-width: 230px;
  &:hover {
    background-color: rgb(240, 240, 240);
    color: rgb(0, 0, 0);

    .task_close_btn {
      opacity: 1 !important;
    }
  }

  .task_header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid rgb(238, 238, 238);
    padding-bottom: 6px;
    color: rgb(0, 0, 0);

    .task_title {
      font-weight: 500;
      line-height: 18px;
      cursor: grab;
      width: 92%;
      font-size: 14px;
    }
    .task_close {
      text-align: center;
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;
      .btn {
        font-size: 11px;
      }

      .task_close_btn {
        transition: all 0.5s ease 0s;
        display: inline-block;
        border: medium none;
        font-size: 8px;
        height: 15px;
        line-height: 1px;
        margin: 0px 0px 8px;
        padding: 0px;
        text-align: center;
        width: 15px;
        background: inherit;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
  .task_creadat {
    font-size: 12px;
  }
  .avatar-sm {
    font-size: 10px;
  }
  .task_asto_creadat {
  }
}

.task_discu_list {
  .avatar {
    font-size: 10px;
  }
}
.task_created_date {
  font-size: 14px;
}

.newcommon {
  &.task-edit-popup {
    .modal-dialog {
      max-width: 900px;
    }

    .task_lft-part {
      border-right: 1px solid #dee2e6;
    }

    .nav-link {
      color: #999;
      padding: 0 20px 10px 0;
      &.active {
        color: $color-primary;
      }
    }

    .task_discu_list {
      max-height: 280px;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.dialpad_icon {
  height: 34px;
  width: 34px;
  cursor: pointer;
  img {
    max-width: 100%;
  }
}
.newcommon {
  &.patient_directory {
    .modal-dialog {
      max-width: 800px;
    }
  }

  .patient_call_list {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .csdial {
    .btn {
      width: 50px;
      height: 50px;
    }
  }
  .call-strip {
    top: -10px;
    left: 0;
    position: relative;
    width: 100%;
  }
}

.buttons {
  padding: 10px 0;
  display: flex;
  gap: 10px;
}

.user-table {
  > :not(:last-child) > :last-child > * {
    border-bottom-color: #dee2e6;
  }
  tbody {
    border-top: 1px solid #dee2e6 !important;
  }
  tr {
    th,
    td {
      vertical-align: middle;
      padding: 12px 0;
    }
  }
}

.api-lists {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.api-list-item {
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 10px 20px;
  .wapi {
    width: 250px;
  }
  .api-action-button {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
}

.all-widget-configration {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.widget-config {
  .list-group {
    .list-group-item {
      padding: 15px 20px;
    }
  }
}

.dashb-filters {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
.dashb-filters select,
.dashb-filters input {
  width: auto;
  min-width: 120px;
}

.result-card {
  margin-bottom: 20px;
  .cardtop {
    padding: 14px 20px;
    text-align: center;
    .cardtop_title {
      font-size: 13px;
      text-transform: uppercase;
    }
    .cardtop_number {
      font-size: 26px;
      font-weight: 600;

      .title {
        font-size: 11px;
        font-weight: normal;
      }

      .value {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .cardbottom {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    .card_left {
      flex: 1 1 0;
      padding: 10px 10px;
      text-align: center;
      .card_left-title {
        font-size: 11px;
        text-transform: uppercase;
      }
      .card_left-number {
        font-size: 15px;
        font-weight: 500;
      }
    }
    .card_right {
      flex: 1 1 0;
      padding: 10px 10px;
      text-align: center;
      .card_right-title {
        font-size: 11px;
        text-transform: uppercase;
      }
      .card_right-number {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.graph-layout {
  width: 100%;
  // background-color: #f1f1f1;
  height: 150px;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}

.graph-matrix-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  .graph-matrix-grid-item {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 10px;
    border-radius: 4px;
    min-height: 95px;
  }
  .graph-matrix-grid-number {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 12px;
  }

  .graph-matrix-grid-lbl {
    font-size: 12px;
  }
  .graph-matrix-grid-res {
    font-size: 12px;
  }
}

.graph-matrix-grid-subtext {
  text-align: center;
  opacity: 0.5;
  font-size: 12px;
}

.sync-leads-table {
  .table {
    > :not(:last-child) > :last-child > * {
      border-bottom-color: #dee2e6;
    }
    tbody {
      border-top: 1px solid #dee2e6 !important;
    }
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 15px 0;
      }

      select {
        max-width: 250px;
      }
    }
  }
}

.reviews-section {
  .avg-reviewbox {
    background-color: #0b928e;
    padding: 20px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 4px;
    .avg-rating {
      font-size: 26px;
      font-weight: 600;
      color: #fff;
    }
    .avg-starts {
      display: flex;
      gap: 3px;
      justify-content: center;
      color: #ffbf00;
    }
  }
  .total-reviewbox {
    background-color: #e7fafa;
    padding: 20px;
    text-align: center;
    border-radius: 4px;
    .total-title {
      color: #0b928e;
    }
    .total-number {
      font-size: 26px;
      font-weight: 600;
      color: #0b928e;
    }
  }
}
.modify-collrail {
  align-items: start;
}

.client-table-details-sec {
  tbody {
    border-top: 1px solid #dee2e6 !important;
  }
  tr {
    thead {
      border: none;
    }
    td {
      vertical-align: middle;
    }
  }
}

.blank-space {
  min-height: 47px;
}

.total-callleadbox {
  background-color: rgb(80, 56, 186);
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 4px;
  .avg-rating {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
  }
}